import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppDBAproveButton from "./AppDBAproveButton";

const AppDBSuccess = ({}) => {
    let [searchParams, setSearchParams] = useSearchParams();

    var [title, setTitle] = useState("Loading...");
    var [message, setMessage] = useState("Loading... Please wait");
    var [instruction, setInstruction] = useState("Loading...");
    var [paymentStatus, setPaymentStatus] = useState(false);

    const asyncPage = async () => {
        if (searchParams.has('csi')) {
            try {
                const res = await fetch("https://apis.jvrcruz.tech/v1/appdb/buyer/payment/verify", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-csi': searchParams.get('csi')
                    },
                })
                if (!res.ok) {
                    console.log(res.body)
                    setTitle("Error");
                    setMessage("Internal error!");
                    setInstruction("Please try again later.");
                } else {
                    const data = await res.json()
                    if (data.user && data.user.paid) {
                        setTitle("Success");
                        setMessage("Your payment has been successfully processed.");
                        setInstruction("Please mark the payment as completed on appdb before requesting access.");
                        setPaymentStatus(true);
                    } else {
                        setTitle("Error");
                        setMessage("Your payment has not been processed.");
                        setInstruction("Please try again later.");
                    }
                }
            } catch (error) {
                console.log(error)
                setTitle("Error");
                setMessage("Internal error!");
                setInstruction("Please try again later.");
            }
        } else {
            setTitle("Error");
            setMessage("Seems that you didn't arrive here from the Stripe payment page. Please try again.");
            setInstruction("Please come back via the Stripe payment page and try again.");	
        }
    }

    asyncPage();
    return ( 
        <div className="success-page">
            <h1>{title}</h1>
            <p>{message}</p>
            <h4>{instruction}</h4>
            <AppDBAproveButton display={paymentStatus} setDisplay={(status) => setPaymentStatus(status)} csi={searchParams.get('csi')}></AppDBAproveButton>
        </div>
     );
}
 
export default AppDBSuccess;