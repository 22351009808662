import AppDBInformationForm from "./AppDBInformationForm";
const Cart = (params) => {
    
   window.location.href = "https://apis.jvrcruz.tech/v1/appdb/buyer/register"
    return ( 
        <div className="appdb-checkout">
            <h1>Redirecting...</h1>
            {/* <p>Fill some identity information before proceeding to checkout</p>
            <AppDBInformationForm /> */}
        </div>
     );
}
 
export default Cart;