const AppDBAproveButton = ({csi, display, setDisplay}) => {
    const handleRequestAccess = async () => {
        if (!csi) {
            alert('An error occured while submitting your request.')
            console.log('No csi provided')
            return
        }
        try {
            const res = await fetch("https://apis.jvrcruz.tech/v1/appdb/buyer/aprove", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-csi': csi
                }
            })
            const data = await res.json()
            if (!res.ok) {
                alert('An error occured while submitting your request.' + data.message)
                console.log(res.body)
            } else {
                setDisplay(false)
                setTimeout(() => {
                    window.location.href = "https://appdb.to/my/p2p-certificates"
                }, 3000)
                alert('Your request of access has been aproved.')
            }
            
        } catch (error) {
            console.log(error)
            alert('An error occured while submitting your request.')
        }
    }
    return ( 
        <div>
            {display && <button onClick={() => handleRequestAccess()}>Request access</button>}
        </div>
     );
}
 
export default AppDBAproveButton;