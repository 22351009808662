import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import CancelPage from './CancelPage';
import SuccessPage from './SucessPage';
import AppDBRedirect from './AppDBRedirect';
import AppDBSuccess from './AppDBSuccess';

function App() {
  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/appdb' element={<AppDBRedirect />}></Route>
        <Route path='/appdb/setup' element={<AppDBSuccess />}></Route>
        <Route path="/success" element={<SuccessPage />} />
        <Route path='/cancel' element={<CancelPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
